<template>
  <div class="emb-signIn-wrap section-gap mt-14">
    <div class="container py-0">
      <v-layout row wrap align-center justify-center>
        <v-flex sm12 md12 lg8 xl7>
          <v-layout row mx-sm-0 mx-3 wrap align-center justify-center>
            <v-flex sm10 md5 lg5 xl6>
              <v-card
                color="accent lighten-1"
                class="sign-in-form form-margin d-block white pa-6"
              >
                <h5 class="text-center">Iniciar sesión</h5>
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="loginUser"
                  >
                    <v-row dense no-gutters>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          type="email"
                          v-model="form.email"
                          required
                          label="Correo electrónico"
                          autocomplete="off"
                          outlined
                        >
                        </v-text-field>
                        <small
                          class="white--text"
                          v-if="errors.email"
                          v-text="errors.email[0]"
                        ></small>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          label="Contraseña"
                          :type="showPassword ? 'text' : 'password'"
                          v-model="form.password"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          outlined
                        >
                        </v-text-field>
                        <small
                          class="white--text"
                          v-if="errors.password"
                          v-text="errors.password[0]"
                        ></small>
                      </v-col>
                    </v-row>
                    <div class="layout align-center justify-space-between">
                      <v-checkbox
                        v-model="form.remember_me"
                        label="Recordarme"
                      ></v-checkbox>
                    </div>
                    <v-btn
                      class="mb-3 ma-0"
                      type="submit"
                      color="primary"
                      :loading="loading"
                      block
                      :disabled="loading || !valid"
                    >
                      Ingresar
                      <template v-slot:loader>
                        <span>Ingresando...</span>
                      </template>
                    </v-btn>
                    <!-- <p class="text-center">
                      <span class="white--text">¿Aún no tienes cuenta? </span>
                      <router-link
                        :to="{ name: 'register' }"
                        class="primary--text"
                        >Registrate aquí</router-link
                      >
                    </p> -->
                  </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      valid: true,
      showPassword: false,
      form: {
        email: null,
        password: null,
        remember_me: false,
      },
      loading: false,
      errors: {},
    };
  },
  created() {
    //
  },
  methods: {
    ...mapActions({
      login: "loginUser",
      addNotification: "addNotification",
    }),
    async loginUser() {
      this.errors = {};
      this.loading = true;
      await this.login(this.form)
        .then((resp) => {
          if (resp.data.success) {
            this.addNotification({
              show: true,
              type: "success",
              message: resp.data.message,
            });

            this.loading = false;
          } else {
            this.addNotification({
              show: true,
              type: "error",
              message: resp.data.message,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            // this.$message.error(error.response.data.message);
            this.addNotification({
              show: true,
              type: "error",
              message: error.response.data.message,
            });
          }

          // if (error.status === 422) {
          // 	this.errors = error.data.errors;
          // } else {
          // 	this.addNotification({
          // 		show: true,
          // 		type: 'error',
          // 		message: error.data.message,
          // 	});
          // }
          // this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    async getLoggedUser() {
      await this.$store.dispatch("getLoggedUser").then((response) => {
        this.addNotification({
          show: true,
          type: "success",
          message: "Bienvenido, inicio de sesion satisfactorio",
        }).then(() => {
          if (response.data.type === "user") {
            this.$router.push({ name: "payment" });
          } else {
            this.$router.push("/dashboard/reports");
          }
        });
      });
    },
  },
};
</script>
